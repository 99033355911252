import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faItchIo } from "@fortawesome/free-brands-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";

function AndrewLinks() {
  return (
    <nav className="links">
      <ul className="nav-links">
        <li>
          <li>
            <a href="https://liminal-zero.itch.io/">
              <FontAwesomeIcon className="link-logo" icon={faItchIo} />
            </a>
          </li>
          <li>
            <a className="link-text" href="https://liminal-zero.itch.io/">
              itch.io
            </a>
          </li>
        </li>

        <li>
          <li>
            <a href="https://github.com/CapelaGames">
              <FontAwesomeIcon className="link-logo" icon={faGithub} />
            </a>
          </li>
          <li>
            <a className="link-text" href="https://github.com/CapelaGames">
              github
            </a>
          </li>
        </li>
        <li>
          <li>
            <a href="https://twitter.com/Liminalzero">
              <FontAwesomeIcon className="link-logo" icon={faTwitter} />
            </a>
          </li>
          <li>
            <a className="link-text" href="https://twitter.com/Liminalzero">
              twitter
            </a>
          </li>
        </li>
      </ul>
    </nav>
  );
}

export default AndrewLinks;
