import ReactDOM from "react-dom";
import cowChess from "./cowchess.png";

function Promo() {
  return (
    <div className="promo">
      <ul className="promo-links">
        <li>
          <a
            className="promo-caption"
            href="https://sxswsydney.com/game/cow-chess/"
          >
            Showing @ SXSW Sydney
          </a>
        </li>
        <li>
          <a href="https://sxswsydney.com/game/cow-chess/">
            <img src={cowChess} className="cowlogos" alt="Cow Chess" />
          </a>
        </li>
      </ul>
    </div>
  );
}
export default Promo;
