import React from "react";
import logo from "./logowhite.png";
import capela from "./capela.png";

function AndrewTitle() {
  return (
    <div className="title">
      <img src={logo} className="title-logo" alt="Logo" />
      <h1 className="title-capela">ANDREW CAPELA</h1>

      <div className="title-text">
        <h1 className="title-title">Game Designer & Programmer</h1>
        <h2 className="title-secondName">
          aka <div className="coloured"> liminal-zero</div>{" "}
        </h2>
        <h3>Delivering experiences with ludo-narrative harmony.</h3>
      </div>
    </div>
  );
}

export default AndrewTitle;
