import ReactDOM from "react-dom";

function Footer() {
  return (
    <footer>
      <div className="footer">
        <small> © 2023 Andrew Capela. All rights reserved. </small>
      </div>
    </footer>
  );
}

export default Footer;
