import ReactDOM from "react-dom";
import Footer from "./footer";
import AndrewTitle from "./andrewTitle";
import AndrewLinks from "./andrewLinks";
import Promo from "./promo";
import "./index.css";

function App() {
  return (
    <div className="container">
      <div className="content-wrapper">
        <AndrewTitle />
        <Promo />
      </div>
      <div className="footer-wrapper">
        <AndrewLinks />
        <Footer />
      </div>
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
